import { IDocument, Document } from "@/model/Document"
import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";

export interface ITermsConditions {
    documentID: string;
    activeFrom: Date;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    document: IDocument;
}

export class TermsConditions implements ITermsConditions {

    constructor(data?: ITermsConditions) {
        if (data) this.update(data);
    }

    update(data: ITermsConditions) {
        mapData(data, {
            root: () => this,
            document: (data: IDocument) => new Document(data)
        });
    }

    documentID: string = "";
    activeFrom: Date = new Date(utils.emptyDateValue);
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    document: Document = new Document();

}