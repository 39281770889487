
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import FileUpload from "@/components/FileUpload.vue"
    import { ITermsConditions, TermsConditions } from "@/model/TermsConditions";
    import apiClient from "@/stuff/ApiClient";
    import fileDownload from "@/stuff/FileDownload";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";

    @Component({ components: { ApiButton, DatePicker, FileUpload } })
    export default class TermsAndConditions extends Vue {

        async mounted() {
            await this.search();
        }

        // computed properties

        get fields(): Array<any> { return [
            { key: "document", label: "Terms & Conditions" },
            { key: "activeFrom", label: "Active From" }
        ]}

        // properties

        searchResults: Array<TermsConditions> = [];
        terms: TermsConditions = new TermsConditions();

        // methods

        search = async () => {
            const response: Array<ITermsConditions> = await apiClient.get(`api/terms/search`);
            this.searchResults.length = 0;
            this.searchResults.push(...response.map(t => new TermsConditions(t)));
        }

        iconUrl(terms: TermsConditions): string {
            if(!terms.document.hasFile) return apiClient.resolveUrl("api/file/icon?extension=nul");
            const filename = terms.document.filename;
            const dotAt = filename.lastIndexOf(".");
            const extension = dotAt === -1 || dotAt >= filename.length - 1 ? "" : filename.substr(dotAt + 1).toLowerCase();
            return apiClient.resolveUrl(`api/file/icon?extension=${extension}`);
        }

        filename(terms: TermsConditions): string {
            return terms.document.hasFile ? terms.document.originalFilename : "No file uploaded";
        }

        download(terms: TermsConditions) {
            fileDownload.download(terms.document);
        }

        addNew = () => {
            utils.resetObject(this.terms);
            this.$bvModal.show("termsUploadModal");
        }

        async edit(terms: ITermsConditions) {
            utils.resetObject(this.terms);
            const response: ITermsConditions = await apiClient.get(`api/terms/${terms.documentID}/load`);            
            this.terms.update(response)
            this.$bvModal.show("termsUploadModal");
        }

        async save(event?: Event) {
            if (!this.terms.document.hasFile) {
                toastr.warning("Please choose a file");
                return;
            }

            const response: { message: string } = await apiClient.post("api/terms/save", this.terms, event);
            if (response.message != "ok") {
                toastr.warning("Failed");
                return;
            }

            toastr.success("Saved");
            this.$bvModal.hide("termsUploadModal");
            this.search();
        }

        async erase(event?: Event) {
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete terms & conditions?", {
                title: "Delete Terms & Conditions",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });

            if (!shouldDelete) return;

            const response: { message: string } = await apiClient.get(`/api/terms/${this.terms.documentID}/delete`, event);
            if (response.message != "ok") {
                toastr.warning("Failed");
                return;
            }

            toastr.success("Deleted");
            this.$bvModal.hide("termsUploadModal");
            this.search();
        }

        cancel() {
            this.$bvModal.hide("termsUploadModal");
        }

    }

